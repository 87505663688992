import styled from "styled-components"

export const Form = styled.form`
  padding: 40px 0;
  display: grid;
  grid-gap: 24px 0;
  width: 100%;
  text-align: left;
`

export const UploadLabel = styled.label<{ error?: number }>`
  text-align: center;
  border: ${({ error }) => (error ? "1px solid red" : "1px solid #ccc")};
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  input[type="file"] {
    display: none;
  }
`

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  min-width: 150px;
  > div .react-datepicker-wrapper {
    width: 100%;
  }
`

export const Label = styled.label`
  padding: 0 0 8px 0;
  color: var(--primary-text);
  text-transform: capitalize;
`

export const FormButton = styled.button`
  padding: 12px 40px;
  width: max-content;
  color: var(--primary-white);
  font-family: "Moderat Regular", sans-serif;
  background: var(--primary-text);
  border: 1px solid var(--primary-text);
  cursor: pointer;
  transition: all 0.3s ease;
  :disabled {
    opacity: 0.7;
    cursor: unset;
  }
  :hover {
    background: white;
    border: 1px solid var(--primary-text);
    color: var(--primary-text);
  }
`

export const FormInput = styled.input<{ error?: number }>`
  padding: 8px 0 2px 0;
  border: none;
  border-bottom: 2px solid var(--primary-text);
  font-size: 1rem;
  border-color: 1px solid var(--primary-text);
  color: var(--primary-text);
  font-family: "Moderat Regular", sans-serif;
  outline-style: none;
`

export const TextArea = styled.textarea<{ error: number }>`
  color: var(--primary-text);
  padding: 8px 0 0 4px;
  font-size: 1rem;
  line-height: 1.5rem;
  border: 1px solid var(--primary-text);
  font-family: "Moderat Regular", sans-serif;
`
