import React, { useState } from "react"
import styled, { css } from "styled-components"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"
import { grow, shrink } from "../../styles/animations/fadeIn"

const NavItem = props => {
  const [hover, setHover] = useState(false)
  const { pathname } = useLocation()
  const { changebg, home, items, primary } = props
  const subPageUrls = items.map(item => item.sub_link_page.url)
  const isSubPageActive = subPageUrls.includes(pathname)
  const isActive = pathname === primary.link_page.url || isSubPageActive
  const {
    primary: { link_page, link_text },
  } = props
  return (
    <Container
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {link_page.link_type.toLowerCase() === "any" ? (
        <Text home={home ? 1 : 0} changebg={changebg ? 1 : 0}>
          {link_text}
        </Text>
      ) : (
        <StyledLink
          rel="prefetch"
          home={home ? 1 : 0}
          changebg={changebg ? 1 : 0}
          active={isActive ? 1 : 0}
          to={link_page.url}
        >
          {link_text}
        </StyledLink>
      )}
      <HoverMenuSpacer changebg={changebg ? 1 : 0} />
      {items && (
        <HoverMenu
          show={hover ? 1 : 0}
          changebg={changebg ? 1 : 0}
          home={home ? 1 : 0}
        >
          <div />
          {items.map((linkItem, idx) => {
            const {
              link_label,
              sub_link_page: { uid, link_type, slug, url },
            } = linkItem
            return link_type.toLowerCase() === "web" ? (
              <ExternalLink
                key={idx}
                home={home}
                changebg={changebg}
                href={url}
                target="_blank"
                rel="noreferrer noopener"
              >
                {link_label ? link_label : uid && uid.split("-").join(" ")}
              </ExternalLink>
            ) : (
              <StyledHoveredLink
                key={uid ?? idx}
                to={url === "/resort" ? "/rooms-layout" : url}
                home={home ? 1 : 0}
                active={pathname === url ? 1 : 0}
                changebg={changebg ? 1 : 0}
              >
                {link_label ? link_label : uid && uid.split("-").join(" ")}
              </StyledHoveredLink>
            )
          })}
        </HoverMenu>
      )}
    </Container>
  )
}

export default NavItem

const Container = styled.div`
  position: relative;
`
const HoverMenuSpacer = styled.div<{ changebg: number }>`
  height: 30px;
  position: absolute;
  background: red;
  width: 200px;
  opacity: 0;
`
const HoverMenu = styled.div<{ show: number; changebg: number; home: number }>`
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 2px 2px 1px rgb(0 0 0 / 10%);
  position: absolute;
  min-width: 100px;
  top: ${({ changebg, home }) => (changebg && home ? "40px" : "55px")};
  left: 0;
  text-align: left;
  width: 240px;
  padding: 8px 15px;
  display: ${({ show }) => (show ? "flex" : "none")};
  transition: all 0.5s ease;
  flex-direction: column;
  animation: ${({ show }) =>
    show
      ? css`
          ${grow} .5s forwards;
        `
      : css`
          ${shrink} .5s forwards;
        `};
`
export const StyledLink = styled(Link)<{
  home?: number
  textType?: string
  changebg?: number
  active?: number
}>`
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-family: "Moderat Medium", sans-serif;
  padding: 4px;
  color: ${({ home, changebg }) =>
    home && changebg ? "var(--primary-white)" : "var(--primary-text)"};
  text-transform: ${({ textType }) => (textType ? textType : "capitalize")};
  transition: color 0.3s ease-in-out, box-shadow 0.5s ease-in-out;
  box-shadow: ${({ active, home }) =>
    !home && active ? " 0 1px 0 var(--primary-text)" : "unset"};
  &:hover {
    box-shadow: 0 1px 0
      ${({ home, changebg }) =>
        home && changebg ? "var(--primary-white)" : "var(--primary-text)"};
  }
`

const StyledHoveredLink = styled(Link)<{
  home: number
  changebg: number
  active?: number
}>`
  text-decoration: none;
  color: ${({ active }) =>
    active ? "var(--primary-blue)" : "var(--primary-text)"};
  text-transform: capitalize;
  font-family: "Moderat Regular", sans-serif;
  padding: 15px 12px;
  transition: all 0.3s ease-in-out;
  :hover {
    transition: all 0.3s ease-in-out;
    color: var(--primary-blue);
  }
`

const ExternalLink = styled.a<{
  home: number
  changebg: number
  active?: number
}>`
  text-decoration: none;
  color: var(--primary-text);
  text-transform: capitalize;
  font-family: "Moderat Regular", sans-serif;
  padding: 15px 12px;
  transition: all 0.3s ease-in-out;
  :hover {
    transition: all 0.3s ease-in-out;
    color: var(--primary-blue);
  }
`
const Text = styled.span<{
  home?: number
  textType?: string
  changebg?: number
  active?: number
}>`
  cursor: pointer;
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-family: "Moderat Medium", sans-serif;
  padding: 4px;
  color: ${({ home, changebg }) =>
    home && changebg ? "var(--primary-white)" : "var(--primary-text)"};
  text-transform: ${({ textType }) => (textType ? textType : "capitalize")};
  transition: color 0.3s ease-in-out, box-shadow 0.5s ease-in-out;
  box-shadow: ${({ active, home }) =>
    !home && active ? " 0 1px 0 var(--primary-text)" : "unset"};
  &:hover {
    box-shadow: 0 1px 0
      ${({ home, changebg }) =>
        home && changebg ? "var(--primary-white)" : "var(--primary-text)"};
  }
`
