import styled from "styled-components"

export const UserFeedbackMessage = styled.span<{ type: "error" | "success" }>`
  text-align: center;
  color: ${({ type }) => {
    switch (type) {
      case "error": {
        return "red"
      }
      case "success": {
        return "var(--primary-text)"
      }
    }
    return null
  }};
`
