import React, { useState } from "react"
import styled from "styled-components"
import { AiOutlineClose } from "react-icons/ai"

import ContactForm from "../molecules/contactForm"

const OwnershipModalButton: React.FC<{
  data: any
  modalType: "ownerRequest" | "openOption"
  text: string
}> = ({ data, modalType, text }) => {
  const [modalOpen, setModalOpen] = useState(false)

  return (
    <>
      <ModalButton
        request={modalType === "ownerRequest"}
        onClick={() => setModalOpen(!modalOpen)}
      >
        {text}
      </ModalButton>
      <OwnershipModal
        data={data}
        modalType={modalType}
        open={modalOpen}
        setModalOpen={setModalOpen}
      />
    </>
  )
}

const OwnershipModal = ({ data, modalType, open, setModalOpen }) => {
  if (open) {
    return (
      <Overlay open={open} onClick={() => setModalOpen(!open)}>
        <Wrapper onClick={e => e.stopPropagation()}>
          <CloseIcon onClick={() => setModalOpen(!open)} />
          <Content>
            <Container>
              <h1>
                {modalType === "openOption"
                  ? "Open Option"
                  : "Wishlist Request"}
              </h1>
              <ContactForm
                modalData={data}
                formType={modalType}
                hasFileUpload={false}
              />
            </Container>
          </Content>
        </Wrapper>
      </Overlay>
    )
  }
  return null
}

export default OwnershipModalButton

const Wrapper = styled.div`
  position: relative;
`
const Content = styled.div`
  max-height: 80vh;
  overflow-y: scroll;
  position: relative;
`
export const Overlay = styled.div<{ open: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  height: 110vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.5);
  display: ${({ open }) => (open ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  z-index: 1000;
`
export const Container = styled.div`
  position: relative;
  padding: 40px 40px 0 40px;
  width: 32vw;
  background: white;
  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
    width: 60vw;
  }
`

const ModalButton = styled.button<{ request: boolean }>`
  font-size: 16px;
  border: none;
  padding: 8px 24px;
  font-family: "Moderat Medium", sans-serif;
  transition: background 0.2s ease-in-out;
  
cursor: pointer;
  text-transform: ${({ request }) => (request ? "uppercase" : "none")};
  background: ${({ request }) => (request ? "#fff" : "var(--primary-text)")};
  color: ${({ request }) =>
    request ? "var(--primary-text)" : "var(--primary-white)"};
  margin: 0 auto;
  :hover {
    background: var(--primary-blue);
    color: white;
`

export const CloseIcon = styled(AiOutlineClose)`
  z-index: 1;
  color: var(--primary-white);
  font-size: 24px;
  position: absolute;
  top: -2px;
  right: -40px;
  cursor: pointer;
  transition: opacity 200ms ease;
  :hover {
    opacity: 0.5;
  }
`
