import { keyframes } from "@emotion/react"

const revealAnimation = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0px, 10px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`

export default revealAnimation
