import * as yup from "yup"

/**
 * Open Option form validation is based on this yup schema
 */
export const openOptionSchema = yup.object().shape({
  roomNumber: yup.string(),
  weekNumber: yup.string(),
  priceRange: yup.string(),
  fullName: yup.string().required(),
  email: yup.string().email().required(),
  phone: yup.number().positive().integer().required(),
  text: yup.string().required(),
})
