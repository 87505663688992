import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import NavItem from "./atoms/navItem"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"
import { NavBookButton } from "./atoms"
import { useNavData } from "../hooks/useNavigationData"
import MobileMenu from "./mobileMenu/mobileMenu"

const Header = ({ changebg, scrolling, previewData }) => {
  const {
    logo,
    alt_logo,
    sub,
    button_text,
    button_link,
    mobileMenu,
    mainNavigationItems,
    membersNavigation,
  } = useNavData(previewData)

  const home = useLocation().pathname === "/"

  return (
    <Wrapper home={home} changebg={changebg} scrolling={scrolling ? 1 : 0}>
      <Container home={home}>
        <Link rel="prefetch" to={"/"}>
          <ImageContainer>
            <Img
              fixed={home && changebg ? logo.fixed : alt_logo.fixed}
              alt={logo.alt || "beach resort logo"}
            />
          </ImageContainer>
        </Link>
        <NavItemsContainer>
          <NavContainer>
            {mainNavigationItems.map((navItem, i) => (
              <NavItem key={i} {...navItem} home={home} changebg={changebg} />
            ))}
          </NavContainer>
          <SubNavContainer>
            <NavItem {...membersNavigation} home={home} changebg={changebg} />
            <a
              href={button_link ? button_link.url : ""}
              target="_blank"
              rel="noreferrer noopener"
            >
              <NavBookButton home={home} changebg={changebg}>
                {button_text}
              </NavBookButton>
            </a>
          </SubNavContainer>
        </NavItemsContainer>
        <MobileMenu
          buttonText={button_text}
          buttonLink={button_link.url}
          logo={logo}
          links={mobileMenu}
          changebg={changebg}
        />
      </Container>
    </Wrapper>
  )
}

export default Header

const Wrapper = styled.div<{
  home?: boolean
  changebg?: boolean
  scrolling: number
}>`
  position: ${({ home }) => (home ? "fixed" : "sticky")};
  top: 0;
  z-index: 2;
  background: ${({ home, changebg }) =>
    (home && !changebg) || !home
      ? "rgba(255, 255, 255, 1)"
      : "rgba(255, 255, 255, 0)"};
  box-shadow: ${({ home, changebg, scrolling }) =>
    (!home && scrolling) || (home && !changebg)
      ? "0 4px 2px -2px rgba(0, 0, 0, 0.1)"
      : "none"};
  width: 100%;
  transition: background 0.5s ease-in, box-shadow 0.4s ease;
`
const Container = styled.div<{ home?: boolean }>`
  position: static;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  width: 90%;
  margin: 0 auto;
  @media (max-width: 1080px) {
    justify-content: flex-end;
  }
  @media (max-width: 750px) {
    padding: 16px 0;
    justify-content: space-between;
  }
`
const ImageContainer = styled.div`
  .gatsby-image-wrapper {
    max-width: 162px;
    max-height: 49px;
  }
`

const NavItemsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 750px) {
    display: none;
  }
`
const NavContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  max-width: 500px;
`
const SubNavContainer = styled.div`
  display: flex;
  align-items: center;
  width: 240px;
  justify-content: space-between;
`
