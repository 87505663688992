import { graphql, useStaticQuery } from "gatsby"
import { mergePrismicPreviewData } from "gatsby-source-prismic"

export const usePopupMessageData = previewData => {
  const staticData = useStaticQuery(graphql`
    query {
      prismicPopupMessage {
        _previewable
        id
        data {
          show_message
          title {
            html
          }
          text {
            html
          }
          button_text
          page {
            uid
          }
        }
      }
    }
  `)
  const mergedData: any = mergePrismicPreviewData({
    staticData,
    previewData,
    strategy: "traverseAndReplace",
  })
  return mergedData.prismicPopupMessage
}
