import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { VscClose } from "react-icons/vsc"
import { usePopupMessageData } from "../hooks/usePopupMessageData"
import { addSeconds } from "date-fns"

const PopupMessage = ({ previewData }) => {
  const { data } = usePopupMessageData(previewData)
  const { show_message, text, title, button_text, page } = data
  const [showMessage, setShowMessage] = useState(false)
  const link = page.uid === "home" ? "/" : "/" + page.uid
  useEffect(() => {
    if (previewData) {
      setShowMessage(true)
      return
    }
    if (!show_message) return
    const storedPopup = localStorage.getItem("popup")
    if (!storedPopup) {
      setShowMessage(true)
      // Make popup expire after a day
      // const expiration = addDays(new Date(), 1)
      // expire after seconds
      const expiration = addSeconds(
        new Date(),
        parseInt(process.env.GATSBY_POP_UP_MESSAGE_DELAY)
      )

      localStorage.setItem("popup", JSON.stringify(expiration))
    } else {
      const expired = new Date(JSON.parse(storedPopup)) < new Date()
      if (expired) localStorage.clear()
    }
  }, [])

  return (
    <Wrapper show={showMessage ? 1 : 0}>
      <Container>
        <CloseIcon onClick={() => setShowMessage(false)} />
        <Title dangerouslySetInnerHTML={{ __html: title.html }} />
        <Text dangerouslySetInnerHTML={{ __html: text.html }} />
        <StyledLink to={link} onClick={() => setShowMessage(false)}>
          {button_text}
        </StyledLink>
      </Container>
    </Wrapper>
  )
}

export default PopupMessage

const Wrapper = styled.div<{ show?: number }>`
  background: rgb(42, 138, 140, 0.98);
  display: ${({ show }) => (show ? "initial" : "none")};
  height: 100vh;
  position: fixed;
  z-index: 2000;
  width: 100%;
`
const Container = styled.div`
  overflow-y: scroll;
  opacity: 1;
  position: relative;
  color: var(--primary-text);
  text-align: center;
  max-width: 60%;
  max-height: 650px;
  margin: 0 auto;
  background: var(--popup-text-bg);
  top: 4rem;
  padding: 4rem;
  @media (max-width: 650px) {
    top: 4rem;
  }
  @media (max-width: 400px) {
    top: 2rem;
    padding: 2rem;
  }
`

const Title = styled.div`
  @media (max-width: 500px) {
    h1 {
      font-size: 2.5rem;
    }
  }
`
const Text = styled.div`
  p {
    margin: 0 0 16px 0;
    line-height: 1.5rem;
  }
`
const StyledLink = styled(Link)`
  text-decoration: none;
  text-transform: uppercase;
  background: var(--primary-text);
  color: #fff;
  padding: 8px 16px;
  display: block;
  width: max-content;
  margin: 40px auto 0 auto;
`
const CloseIcon = styled(VscClose)`
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 2rem;
  cursor: pointer;
  @media (max-width: 400px) {
    top: 8px;
    right: 8px;
  }
`
