import React from "react"
import line from "../../assets/icons/line.png"
import styled from "styled-components"

const MobileTimeAndTemp = ({ time, temp }) => {
  return (
    <Container>
      <Text>{time}</Text>
      <Line src={line} alt="line" />
      {temp ? <Text>{temp} C&#176;</Text> : null}
    </Container>
  )
}

export default MobileTimeAndTemp

const Container = styled.div`
  grid-area: timeTemp;
  display: flex;
  align-items: center;
  @media (min-width: 600px) {
    display: none;
  }
`

const Line = styled.img`
  position: relative;
  top: 4px;
  display: block;
  height: 2px;

  padding: 0 8px;
  width: 50px;
`
const Text = styled.p`
  color: var(--primary-text);
  font-family: "Moderat Regular", sans-serif;
  padding: 8px 0;
`
