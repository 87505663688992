import React from "react"
import styled from "styled-components"
import { BsChevronDown } from "react-icons/bs"

const optionStyles = {
  height: "50px",
}

const FormSelect = ({ selected, type, name, options, selectRef, onChange }) => {
  return (
    <CustomSelect>
      <Select
        // id={name}
        name={name}
        ref={selectRef}
        onChange={onChange}
      >
        <Option value="" disabled selected>
          {type === "contact" ? "Select a department" : "Choose a room type"}
        </Option>
        {options.map((option, i) => {
          return (
            <Option
              key={i}
              value={option.text}
              style={optionStyles}
              selected={selected && selected === option.text}
              disabled={type === "openOption"}
            >
              {option.text}
            </Option>
          )
        })}
      </Select>
      <Icon />
    </CustomSelect>
  )
}

export default FormSelect

const Select = styled.select`
  font-size: 1rem;
  position: relative;
  padding: 5px 0;
  border: none;
  color: var(--primary-text);
  font-family: "Moderat Regular", sans-serif;
  width: 100%;
  -webkit-appearance: none;
  background-color: transparent;
  border-bottom: 2px solid var(--primary-text);
`

const Option = styled.option`
  padding: 8px;
  background: white;
  color: var(--primary-text);
  height: 24px !important;
  font-family: "Moderat Regular", sans-serif;
`

const CustomSelect = styled.div`
  position: relative;
  font-size: 1rem;
`
const Icon = styled(BsChevronDown)`
  font-size: 1.5rem;
  font-weight: bold;
  position: absolute;
  right: 0;
  z-index: 1;
  color: var(--primary-text);
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
`
