import React, { useEffect, useState } from "react"
import styled from "styled-components"

const FadeInWrapper: React.FC<{ duration: number }> = props => {
  const [isVisible, setVisible] = useState(false)

  const domRef = React.useRef()

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      // In your case there's only one element to observe:
      if (entries[0].isIntersecting) {
        // Not possible to set it back to false like this:
        setVisible(true)

        // No need to keep observing:
        observer.unobserve(domRef.current)
      }
    })

    observer.observe(domRef.current)

    // return () => observer.unobserve(domRef.current)
  }, [])

  return (
    <Wrapper ref={domRef} isVisible={isVisible} duration={props.duration}>
      {props.children}
    </Wrapper>
  )
}

export default FadeInWrapper

const Wrapper = styled.div<{ isVisible: boolean; duration: number }>`
  ${({ isVisible, duration }) =>
    !isVisible
      ? `opacity: 0;`
      : `opacity: 1;
  transform: none;
  transition: all ${duration.toString() || "1.5"}s ease;`}
`
