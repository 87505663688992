import { keyframes } from "styled-components"

export const fade = keyframes`
from {
  opacity: 0
}
to {
  opacity: 1
}
`

export const grow = keyframes`
  from {
    opacity: 0;
    transform: scale3d(0);
  }
  to {
    opacity: 1;
    transform: scale3d(1);
  }
`

export const shrink = keyframes`
  from {
    opacity: 1;
    transform: scale3d(1.1);
  }
  to {
    opacity: 0;
    transform: scale3d(0);
  }
`
