import styled from "styled-components"
import { Link } from "gatsby"

export const Text = styled.p`
  color: ${({ color }) => color || "var(--primary-text)"};
  margin: 0 0 1rem 0;
  font-family: "Moderat Regular", sans-serif;
  line-height: 20pt;
  padding: 0 16px 0 0;

  @media (max-width: 776px) {
    //text-align: center;
    //padding: 0 16px;
  }
`
export const TextPreview = styled.p<{ clamp?: number }>`
  color: ${({ color }) => color || "var(--primary-text)"};
  margin: 0 0 1rem 0;
  font-family: "Moderat Regular", sans-serif;
  display: -webkit-box;
  -webkit-line-clamp: ${({ clamp }) => clamp || 3};
  -webkit-box-orient: vertical;
  overflow: hidden;
`
export const InternalReadMore = styled(Link)`
  text-decoration: none;
  color: ${({ color }) => color || "var(--primary-text)"};
  font-family: "Moderat Bold", sans-serif;
  width: max-content;
`

export const Date = styled.p`
  color: ${({ color }) => color || "var(--primary-text)"};
  font-family: "Moderat Regular", sans-serif;
  align-self: end;
`

export const Span = styled.span<{ active?: number }>`
  display: block;
  position: relative;
  transition: width 300ms, transform 300ms;
  ::after {
    transition: width 300ms, transform 300ms;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: ${({ active }) => (active ? "100%" : "0")};
    height: 1px;
    background-color: var(--primary-text);
  }
  :hover::after {
    transition: width 300ms, transform 300ms;
    width: 100%;
  }
  :active {
    color: var(--primary-blue);
  }
`
