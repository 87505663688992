import React from "react"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"
import { Link } from "gatsby"

import { Text } from "../atoms/text"

const StayHere = props => {
  const { background_image, section_text, section_title, items } = props

  return (
    <Container fluid={background_image.fluid}>
      <Content>
        <Title dangerouslySetInnerHTML={{ __html: section_title.html }} />
        <Text color="#fff">{section_text.text}</Text>
        {items.length !== 0 ? (
          <ButtonContainer>
            {items.map(({ button_link, button_text }, i) => {
              if (button_link.link_type === "Web") {
                return (
                  <Button
                    key={i}
                    href={button_link.url}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {button_text.text}
                  </Button>
                )
              }
              return (
                <InternalLink key={i} to={button_link.url}>
                  {button_text.text}
                </InternalLink>
              )
            })}
          </ButtonContainer>
        ) : null}
      </Content>
    </Container>
  )
}

export default StayHere

const Container = styled(BackgroundImage)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding: 120px 0;
`
const Content = styled.div`
  width: 550px;
  @media (max-width: 550px) {
    width: auto;
  }
`
const Title = styled.div`
  h1 {
    color: #fff;
  }
`
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`
const Button = styled.a`
  text-decoration: none;
  background: var(--primary-white);
  margin: 8px 16px;
  color: var(--primary-text);
  font-family: "Moderat Medium", sans-serif;
  text-transform: uppercase;
  width: 155px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.2s ease-in-out;
  :hover {
    background: var(--primary-blue);
    color: white;
  }
`

const InternalLink = styled(Link)`
  text-decoration: none;
  background: var(--primary-white);
  margin: 8px 16px;
  color: var(--primary-text);
  font-family: "Moderat Medium", sans-serif;
  text-transform: uppercase;
  width: 155px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.2s ease-in-out;
  :hover {
    background: var(--primary-blue);
    color: white;
  }
`
