import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { BookButton } from "../atoms"

const BookNowBanner = ({ text, button_text, link }) => {
  return (
    <Container>
      <Tagline>{text.text}</Tagline>
      {link.link_type && link.link_type.toLowerCase() === "document" ? (
        <InternalBookButton to={link.url}>{button_text}</InternalBookButton>
      ) : (
        <BookButton href={link.url} target="_blank" rel="noopener noreferer">
          {button_text}
        </BookButton>
      )}
    </Container>
  )
}

export default BookNowBanner

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #eceae8;
  padding: 40px 16px;
  @media (max-width: 800px) {
    flex-direction: column;
    text-align: center;
  }
`

const Tagline = styled.h1`
  font-family: "Canela Light", sans-serif;
  font-size: 2.2rem;
  margin: 1rem 0 1rem 0;
  color: var(--primary-text);
`

const InternalBookButton = styled(Link)`
  padding: 4px 28px;
  display: grid;
  place-items: center;
  border: 1px solid var(--primary-text);
  color: var(--primary-text);
  background: transparent;
  cursor: pointer;
  font-family: "Moderat Regular", sans-serif;
  font-size: 24px;
  height: 42px;
  text-transform: capitalize;
  min-width: 143px;
  margin: 0 0 0 32px;
  text-decoration: none;
  text-align: center;
  transition: all 0.2s ease-in-out;
  :hover {
    background: var(--primary-text);
    color: white;
    transition: all 0.2s ease-in-out;
  }
  @media (max-width: 740px) {
    margin: 0;
  }
`
