import * as yup from "yup"

/**
 * Concierge form validation is based on this yup schema
 */
export const conciergeSchema = yup.object().shape({
  fullName: yup.string().required(),
  email: yup.string().email().required(),
  phone: yup.number().positive().integer().required(),
  text: yup.string().required(),
})
