import { graphql, useStaticQuery } from "gatsby"
import { mergePrismicPreviewData } from "gatsby-source-prismic"

export const useNavData = previewData => {
  const { prismicNavigation } = useStaticQuery(
    graphql`
      query {
        prismicNavigation {
          _previewable
          uid
          data {
            button_text
            button_link {
              url
            }
            logo {
              alt
              fixed(width: 162, height: 49) {
                height
                width
                src
                srcSet
              }
              fluid {
                aspectRatio
                sizes
                src
                srcSet
              }
            }
            alt_logo {
              alt
              fixed(width: 162, height: 49) {
                height
                src
                srcSet
                width
              }
              fluid {
                aspectRatio
                sizes
                src
                srcSet
              }
            }
            body {
              ... on PrismicNavigationBodyMobileMenu {
                id
                slice_type
                primary {
                  link_group_name
                }
                items {
                  page {
                    uid
                    slug
                    url
                  }
                  link_text
                }
              }
              ... on PrismicNavigationBodyMembersNavigation {
                id
                slice_type
                primary {
                  link_text
                  link_page {
                    slug
                    link_type
                    url
                  }
                }
                items {
                  link_label
                  sub_link_page {
                    uid
                    slug
                    link_type
                    url
                  }
                }
              }
              ... on PrismicNavigationBodyMainNavigationItem {
                id
                slice_type
                items {
                  link_label
                  sub_link_page {
                    uid
                    slug
                    link_type
                    url
                  }
                }
                primary {
                  link_text
                  link_page {
                    uid
                    slug
                    link_type
                    url
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  let data: any
  if (previewData) {
    data = mergePrismicPreviewData({
      staticData: prismicNavigation,
      previewData,
      strategy: "rootReplaceOrInsert",
    }).data
  } else {
    data = prismicNavigation.data
  }
  const mobileMenu = data.body
    .map(slice => slice)
    .filter(slice => slice.slice_type === "mobile_menu")
  const mainNavigationItems = data.body
    .map(slice => slice)
    .filter(slice => slice.slice_type === "main_navigation_item")
  const membersNavigation = data.body.find(
    slice => slice.slice_type === "members_navigation"
  )
  data.mobileMenu = mobileMenu
  data.mainNavigationItems = mainNavigationItems
  data.membersNavigation = membersNavigation
  return data
}
