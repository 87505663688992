import { graphql, useStaticQuery } from "gatsby"

export const useGitData = () => {
  const { allPlainText } = useStaticQuery(graphql`
    query {
      allPlainText {
        nodes {
          content
        }
      }
    }
  `)
  return allPlainText.nodes[0].content
}
