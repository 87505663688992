import { useGitData } from "../hooks/useGitData"

/**
 * Logs the latest commit message of the last build
 */
export const handleGit = () => {
  const lastCommit = useGitData()
  const isDev = process.env.NODE_ENV === "development"
  isDev && console.log(lastCommit)
}
