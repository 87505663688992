import React from "react"
import styled from "styled-components"

interface ClientLinkProps {
  type: "mail" | "phone"
  value: string
}

const ClientLink: React.FC<ClientLinkProps> = ({ type, value }) => {
  const linkPrefix = type === "mail" ? "mailto:" : "tel:"
  return <Link href={`${linkPrefix}${value}`}>{value}</Link>
}

export default ClientLink

const Link = styled.a`
  line-height: 1.4rem;
  display: block;
  font-family: "Moderat Regular", sans-serif;
  width: max-content;
  text-decoration: none;
  color: var(--primary-text);
  position: relative;
  transition: width 300ms, transform 300ms;
  ::after {
    transition: width 300ms, transform 300ms;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 1px;
    background-color: var(--primary-text);
  }
  :hover::after {
    transition: width 300ms, transform 300ms;
    width: 100%;
  }
`
