import styled from "styled-components"

export const Main = styled.main`
  max-width: 1600px;
  margin: 0 auto;
`

export const SectionTitle = styled.h1<{
  width?: string
  color?: string
  align?: string
}>`
  font-family: "Canela Light", sans-serif;
  font-size: 3.6rem;
  margin: 1rem 0 1rem 0;
  color: ${({ color }) => color || "var(--primary-text)"};
  text-align: ${({ align }) => align || "center"};
  max-width: ${({ width }) => width};
  @media (max-width: 1024px) {
  }
  @media (max-width: 776px) {
    text-align: center;
  }
  @media (max-width: 500px) {
    font-size: 3.2rem;
  }
`
export const SectionSubTitle = styled.p<{ width?: string }>`
  text-align: center;
  max-width: 600px;
  color: ${({ color }) => color || "var(--primary-text)"};
  font-family: "Moderat Regular", sans-serif;
  margin: 0 auto 1rem auto;
  font-size: 1.1rem;
`
export const TextHeading = styled.h2`
  color: ${({ color }) => color || "var(--primary-text)"};
  font-family: "Moderat Regular", sans-serif;
  margin: 0 auto 2rem auto;
`
export const SectionText = styled.p<{ width?: string }>`
  text-align: center;
  max-width: ${({ width }) => width};
  color: ${({ color }) => color || "var(--primary-text)"};
  font-family: "Moderat Regular", sans-serif;
  margin: 0 auto 2rem auto;
  line-height: 24px;
`

export const TitleSpan = styled.span`
  font-family: "Canela Light Italic", sans-serif;
`

export const Tagline = styled.p`
  color: ${({ color }) => color || "var(--primary-text)"};
  margin: 1rem 0;
  font-family: "Moderat Regular", sans-serif;
  line-height: 20pt;
  padding: 0 16px 0 0;
  text-align: center;
  @media (max-width: 776px) {
    padding: 0 16px;
  }
`

export const Text = styled.p<{ position?: string }>`
  color: ${({ color }) => color || "var(--primary-text)"};
  margin: 0 0 1rem 0;
  font-family: "Moderat Regular", sans-serif;
  line-height: 20pt;
  padding: 0 16px 0 0;
  @media (max-width: 776px) {
    text-align: ${({ position }) => (position ? position : "center")};
  }
`

export const BookButton = styled.a`
  padding: 4px 28px;
  display: grid;
  place-items: center;
  border: 1px solid var(--primary-text);
  color: var(--primary-text);
  background: transparent;
  cursor: pointer;
  font-family: "Moderat Regular", sans-serif;
  font-size: 24px;
  height: 42px;
  text-transform: capitalize;
  min-width: 143px;
  margin: 0 0 0 32px;
  text-decoration: none;
  text-align: center;
  transition: all 0.2s ease-in-out;
  :hover {
    background: var(--primary-text);
    color: white;
    transition: all 0.2s ease-in-out;
  }
  @media (max-width: 740px) {
    margin: 0;
  }
`
export const NavBookButton = styled.button<{
  home?: boolean
  changebg: boolean
}>`
  border: none;
  padding: 8px 16px;
  min-width: 120px;
  background: ${({ home, changebg }) =>
    home && changebg ? "var(--primary-white)" : "var(--primary-text)"};
  color: ${({ home, changebg }) =>
    home && changebg ? "var(--primary-text)" : "var(--primary-white)"};
  font-family: "Moderat Regular", sans-serif;
  font-size: 16px;
  text-transform: capitalize;
  max-width: 200px;
  height: 41px;
  cursor: pointer;
  transition: all 0.2s ease-in;

  ${({ home, changebg }) =>
    home && changebg
      ? `
      &:hover {
      background: var(--primary-blue);
      color: var(--primary-white);
      }
      &:active {
      background: var(--primary-blue);
      opacity: 0.7;
      color: var(--primary-white);
      }`
      : `
      &:hover {
      background: var(--primary-blue);
      color: var(--primary-white);
      }
      &:active {
      background: var(--primary-text);
      opacity: 0.3;
      color: var(--primary-white);
      }`}
`
