import React from "react"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"
import { Text } from "./atoms/text"
import { Link } from "gatsby"
import OwnershipModalButton from "./modals/ownershipModalButton"

const TellUs = ({
  text_one,
  text_two,
  title,
  image,
  button_text,
  button_link,
}) => {
  return (
    <Container fluid={image.fluid}>
      <Content>
        <Text color="#fff">{text_one.text}</Text>
        <Title dangerouslySetInnerHTML={{ __html: title.html }} />
        <Text color="#fff">{text_two.text}</Text>
        <ButtonTitle>{button_text.text}</ButtonTitle>
        {button_link ? (
          <Button to={button_link}>{button_text}</Button>
        ) : (
          <OwnershipModalButton
            data={undefined}
            modalType={"ownerRequest"}
            text={button_text}
          />
        )}
      </Content>
    </Container>
  )
}

export default TellUs

const Container = styled(BackgroundImage)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding: 120px 0;
  z-index: 1;
`
const Content = styled.div`
  width: 550px;
  @media (max-width: 550px) {
    width: auto;
  }
`
const Title = styled.div`
  font-size: 2rem;
  h2,
  p {
    margin: 0 0 1rem 0;
    color: #fff;
    font-family: "Canela Light", sans-serif;
  }
  //em {
  //  font-family: Canela Light Italic, sans-serif;
  //  font-style: italic;
  //}
`
const ButtonTitle = styled.p`
  color: var(--primary-text);
  font-family: "Moderat Regular", sans-serif;
  font-size: 20pt;
`

const Button = styled(Link)`
  text-decoration: none;
  margin: 40px auto 0 auto;
  background: #fff;
  padding: 8px 24px;
  color: var(--primary-text);
  font-family: "Moderat Medium", sans-serif;
  text-transform: uppercase;
  display: block;
  width: max-content;
  justify-self: center;
  transition: background 0.2s ease-in-out;
  :hover {
    background: var(--primary-blue);
    color: white;
  }
`
