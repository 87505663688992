import React from "react"
import styled from "styled-components"
import { FaAngleRight } from "react-icons/fa"
import { Link } from "gatsby"

const SubMenu = ({ links }) => {
  return (
    <>
      {links.map(link => (
        <React.Fragment key={link.page.uid}>
          <StyledLink key={link.page.uid} to={link.page.url}>
            {link.link_text}
          </StyledLink>
          <Icon>
            <FaAngleRight />
          </Icon>
        </React.Fragment>
      ))}
    </>
  )
}

export default SubMenu

const Icon = styled.div`
  display: flex;
  background: white;
  color: var(--primary-text);
  height: 100%;
  align-items: center;
`

const StyledLink = styled(Link)`
  background: white;
  color: var(--primary-text);
  justify-self: center;
  width: 100%;
  text-align: center;
  padding: 16px 0;
  text-decoration: none;
`
