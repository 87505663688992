import * as yup from "yup"

/**
 * Owner Request form validation is based on this yup schema
 */
export const ownerRequestSchema = yup.object().shape({
  roomNumber: yup.string(),
  weekNumber: yup.string(),
  priceRange: yup.number(),
  fullName: yup.string().required(),
  email: yup.string().email().required(),
  phone: yup.number().positive().integer().required(),
  text: yup.string().required(),
})
