import React, { useState } from "react"
import styled from "styled-components"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import {
  Form,
  FormButton,
  FormInput,
  InputContainer,
  Label,
  TextArea,
  UploadLabel,
} from "../atoms/forms"
import FormSelect from "../atoms/forms/select"
import { UserFeedbackMessage } from "../atoms/userFeedback"

import { vacancySchema } from "../../utils/yupSchemas/vacancySchema"
import { contactSchema } from "../../utils/yupSchemas/contactSchema"
import { conciergeSchema } from "../../utils/yupSchemas/conciergeSchema"
import { ownerRequestSchema } from "../../utils/yupSchemas/ownerRequestSchema"

import formatCurrency from "../../utils/formatCurrency"
import { openOptionSchema } from "../../utils/yupSchemas/openOptionSchema"

const roomTypes = [
  { text: "One Bedroom" },
  { text: "Two Bedroom" },
  { text: "Studio" },
]

const schemas = {
  vacancy: vacancySchema,
  contact: contactSchema,
  concierge: conciergeSchema,
  ownerRequest: ownerRequestSchema,
  openOption: openOptionSchema,
}

interface FormProps {
  formType: "contact" | "vacancy" | "concierge" | "ownerRequest" | "openOption"
  hasFileUpload: boolean
  departments?: []
  modalData?: {
    week: string
    room: string
    type: string
    price: string
  }
}

const getTextAreaPlaceholder = (formType: FormProps["formType"]): string => {
  let placeholderText = ""
  switch (formType) {
    case "contact":
      placeholderText = "A brief summary for contacting us..."
      break
    case "vacancy":
      placeholderText = "Please provide a brief motivation..."
      break
    case "concierge":
      placeholderText = "How can we be of service..."
      break
    case "ownerRequest":
    case "openOption":
      placeholderText = "Some background information..."
      break
  }

  return placeholderText
}

/**
 * Generic form for vacancy, contact and concierge pages; They render different things based on form type and hasFileUpload
 * @param formType
 * @param hasFileUpload
 * @param departments
 * @param modalData
 * @constructor
 */
const ContactForm: React.FunctionComponent<FormProps> = ({
  formType,
  hasFileUpload,
  departments,
  modalData,
}) => {
  const [file, setFile] = useState(undefined)
  const [fileFormatError, setFileFormatError] = useState(undefined)
  const [formName, setFormName] = useState(formType)
  const [submitSuccessful, setSubmitSuccessful] = useState(undefined)
  const [submitError, setSubmitError] = useState(undefined)
  const { register, errors, handleSubmit } = useForm({
    resolver: yupResolver(schemas[formType]),
  })

  const formIsValid = hasFileUpload
    ? !!(Object.keys(errors).length === 0 && file)
    : Object.keys(errors).length === 0

  const handleFileUpload = e => {
    setFileFormatError(undefined)
    setSubmitError(undefined)
    const uploadedFile = e.target.files[0]
    if (!uploadedFile) {
      return
    }
    const fileExtension = uploadedFile.name.split(".").reverse()[0]
    const fileSize = uploadedFile.size
    if (fileExtension !== "pdf") {
      return setFileFormatError("File should be in a .pdf format")
    }
    if (fileSize >= 262144) {
      return setFileFormatError("File should smaller than 2 Mb")
    }
    setFile(e.target.files[0])
  }
  const submitFunction = async (data, e) => {
    e.preventDefault()
    setSubmitSuccessful(undefined)
    setSubmitError(undefined)
    const formData = hasFileUpload ? { ...data, file } : data

    const getDepartmentMail = () => {
      if (formType === "concierge") return "concierge@playalinda.com"
      if (departments) {
        // @ts-ignore
        return departments.find(d => d.name.text === formData.department).email
          .text
      }
    }

    fetch("/.netlify/functions/email-api", {
      method: "post",
      body: JSON.stringify({
        ...formData,
        type: formType,
        departmentMail: getDepartmentMail(),
      }),
    })
      .then(res => {
        setSubmitSuccessful(true)
        const formElement = document.getElementsByName(
          formName
        )[0] as HTMLFormElement
        formElement.reset()
        return res.json()
      })
      .catch(error => {
        console.error({ error })
        setSubmitError(
          "Something went wrong, please contact us by telephone or mail."
        )
      })
  }

  return (
    <Form
      data-netlify
      netlify-honeypot="bot-field"
      onSubmit={handleSubmit(submitFunction)}
      method="post"
      name={formName}
    >
      {/* Necessary for Netlify forms */}
      <input type="hidden" name="form-name" value={formName} />
      {formType === "ownerRequest" || formType === "openOption" ? (
        <OwnerRequestContainer>
          <InputContainer>
            <Label htmlFor="roomNumber">Room number</Label>
            <FormInput
              placeholder="Room number..."
              id="roomNumber"
              name="roomNumber"
              type="text"
              value={formType === "openOption" ? modalData.room : undefined}
              readOnly={formType === "openOption"}
              ref={register()}
              error={errors.roomNumber ? 1 : 0}
            />
          </InputContainer>
          <InputContainer>
            <Label htmlFor="weekNumber">Week number</Label>
            <FormInput
              placeholder="Week number..."
              id="weekNumber"
              name="weekNumber"
              type="text"
              value={formType === "openOption" ? modalData.week : undefined}
              readOnly={formType === "openOption"}
              ref={register()}
              error={errors.weekNumber ? 1 : 0}
            />
          </InputContainer>
          <InputContainer>
            <Label htmlFor="roomType">Room Type</Label>
            <FormSelect
              type={formType}
              selectRef={register}
              name="roomType"
              selected={formType === "openOption" ? modalData.type : formName}
              options={roomTypes}
              onChange={
                formType === "openOption"
                  ? e => setFormName(e.target.value)
                  : () => null
              }
            />
          </InputContainer>
          <InputContainer>
            <Label htmlFor="priceRange">Price Range</Label>
            <FormInput
              placeholder="Price range..."
              id="priceRange"
              name="priceRange"
              type="text"
              value={
                formType === "openOption"
                  ? formatCurrency(modalData.price)
                  : undefined
              }
              readOnly={formType === "openOption"}
              ref={register()}
              error={errors.weekNumber ? 1 : 0}
            />
          </InputContainer>
        </OwnerRequestContainer>
      ) : null}
      <InputContainer>
        <Label htmlFor="fullName">Full Name*</Label>
        <FormInput
          placeholder="Your name..."
          id="fullName"
          name="fullName"
          type="text"
          ref={register()}
          error={errors.fullName ? 1 : 0}
        />
      </InputContainer>
      <InputContainer>
        <Label htmlFor="email">Email*</Label>
        <FormInput
          placeholder="Your email..."
          id="email"
          name="email"
          type="email"
          ref={register()}
          error={errors.email ? 1 : 0}
        />
      </InputContainer>
      <InputContainer>
        <Label htmlFor="phone">Phone*</Label>
        <FormInput
          placeholder="Your phone number..."
          id="phone"
          name="phone"
          type="number"
          ref={register()}
          error={errors.phone ? 1 : 0}
        />
      </InputContainer>
      {formType === "contact" ? (
        <InputContainer>
          <Label>Department*</Label>
          <FormSelect
            selected={undefined}
            type={formType}
            selectRef={register}
            name="department"
            // @ts-ignore
            options={departments.map(department => department.name)}
            onChange={e => setFormName(e.target.value)}
          />
        </InputContainer>
      ) : null}
      <InputContainer>
        <Label htmlFor="text">
          {formType === "vacancy" ? "Motivation*" : "Message*"}
        </Label>
        <TextArea
          placeholder={getTextAreaPlaceholder(formType)}
          id="text"
          name="text"
          rows={4}
          ref={register()}
          error={errors.text ? 1 : 0}
        />
      </InputContainer>
      {hasFileUpload ? (
        <UploadLabel htmlFor="file">
          <FormInput
            id="file"
            aria-label="file"
            name="file"
            type="file"
            accept="application/pdf"
            onChange={handleFileUpload}
          />
          Upload CV*
        </UploadLabel>
      ) : null}
      {file ? <Text>{file.name}</Text> : null}
      {submitSuccessful && !submitError ? (
        <UserFeedbackMessage type="success">
          Thank you for reaching out to us, we will get back to you shortly.
        </UserFeedbackMessage>
      ) : (
        <UserFeedbackMessage type="error">
          {submitError || fileFormatError}
        </UserFeedbackMessage>
      )}
      {/*Anti bot field*/}
      <label hidden htmlFor="bot-field">
        Don’t fill this out if you're human: <input name="bot-field" />
      </label>

      <FormButton
        aria-label="Sign up"
        type="submit"
        disabled={!formIsValid || fileFormatError}
      >
        Send
      </FormButton>
    </Form>
  )
}

export default ContactForm

const Text = styled.p`
  text-align: center;
`

const OwnerRequestContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
  @media (max-width: 650px) {
    grid-template-columns: 1fr;
  }
`
