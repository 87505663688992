import React, { SyntheticEvent, useEffect, useRef, useState } from "react"
import styled from "styled-components"
import Reveal, { Fade } from "react-awesome-reveal"
import { BsChevronDown } from "react-icons/bs"

import revealAnimation from "../../styles/animations/revealAnimation"

const Hero = ({
  tagline,
  title,
  scroll_down_text,
  toggleBackground,
  video,
}) => {
  const scroll = async () => {
    const target = document.querySelector("#experience")
    target.scrollIntoView({
      behavior: "smooth",
    })
    setTimeout(() => {
      toggleBackground(false)
    }, 800)
  }
  return (
    <Wrapper>
      <Fade triggerOnce>
        <Container>
          <Content>
            <Reveal
              duration={4000}
              delay={2000}
              keyframes={revealAnimation}
              triggerOnce
            >
              <Title dangerouslySetInnerHTML={{ __html: title.html }} />
              <Tagline>{tagline.text}</Tagline>
            </Reveal>
          </Content>
          <LinkContainer onClick={scroll}>
            <Reveal
              duration={4000}
              delay={2000}
              keyframes={revealAnimation}
              triggerOnce
            >
              <Link>{scroll_down_text.text}</Link>
              <Icon />
            </Reveal>
          </LinkContainer>
        </Container>
      </Fade>
      <Video preload="auto" autoPlay muted playsInline loop>
        <source src={video.url} type="video/mp4" />
      </Video>
    </Wrapper>
  )
}

export default Hero

const Wrapper = styled.div`
  position: relative;
`
const Video = styled.video`
  height: 100vh;
  width: 100%;
  object-fit: cover;
  position: relative;
  z-index: -1;
`
const Container = styled.div`
  height: 100vh;
  width: 100%;
  margin-bottom: 16px;
  display: grid;
  justify-content: center;
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`
const Title = styled.div`
  h1 {
    color: #fff;
  }
`

const Content = styled.div`
  //max-width: 750px;
  align-self: flex-end;
  justify-self: center;
  padding: 0 8px;
  text-shadow: 1px 1px 40px black;
  background: rgba(0, 0, 0, 0.05);
`

const Tagline = styled.p`
  text-align: center;
  color: var(--primary-white);
  max-width: 574px;
  margin: 0 auto;
  font-family: "Moderat Regular", sans-serif;
  line-height: 24px;
`
const LinkContainer = styled.div`
  justify-self: center;
  align-self: flex-end;
  position: relative;
  bottom: 80px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  text-shadow: 1px 1px 40px black;
  :hover {
    bottom: 72px;
    transition: all 0.3s ease-in-out;
  }
`
const Link = styled.p`
  color: var(--primary-white);
  font-family: "Moderat Bold", sans-serif;
  text-transform: capitalize;
  text-align: center;
  font-size: 20pt;
  margin-bottom: 24px;
`

const Icon = styled(BsChevronDown)`
  font-size: 40px;
  cursor: pointer;
  display: block;
  margin: 0 auto;
  color: white;
`
