import { graphql, useStaticQuery } from "gatsby"

export const useFooterData = () => {
  const { allPrismicFooter } = useStaticQuery(graphql`
    query {
      allPrismicFooter {
        nodes {
          data {
            left_section_title {
              html
            }
            column_one {
              label
              page {
                uid
                url
                link_type
              }
            }
            column_two {
              label
              page {
                uid
                url
                link_type
              }
            }
            column_three {
              label
              page {
                uid
                url
                link_type
              }
            }
            column_four {
              label
              page {
                uid
                url
                link_type
              }
            }
            middle_section_title {
              html
            }
            contact_column {
              information
            }
            right_section_title {
              html
            }
            partners {
              image {
                alt
                fluid {
                  aspectRatio
                  src
                  srcSet
                  sizes
                }
                fixed(width: 100) {
                  base64
                  height
                  width
                  src
                  srcSet
                }
              }
            }
            socials {
              link
              image {
                alt
                fixed(width: 22, height: 22) {
                  base64
                  height
                  src
                  srcSet
                  width
                }
              }
            }
          }
        }
      }
    }
  `)
  const data = allPrismicFooter.nodes[0].data

  return { data }
}
