import React, { useState } from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { Link } from "gatsby"
import { VscMenu, VscClose } from "react-icons/vsc"
import { useLocation } from "@reach/router"

import Navigation from "./navigation"

const MobileMenu = ({ logo, buttonText, links, changebg, buttonLink }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const { pathname } = useLocation()
  const home = pathname === "/"
  return (
    <Container>
      <NavBookButton
        href={buttonLink}
        target="_blank"
        rel="noreferrer noopener"
        home={home ? 1 : 0}
        changebg={changebg ? 1 : 0}
      >
        {buttonText}
      </NavBookButton>
      <MenuIcon
        onClick={() => setIsOpen(!isOpen)}
        home={home ? 1 : 0}
        changebg={changebg ? 1 : 0}
      />
      <MenuWrapper open={isOpen}>
        <Header>
          <StyledLink to="/" onClick={() => setIsOpen(!isOpen)}>
            <Img fixed={logo.fixed} />
          </StyledLink>

          <CloseIcon onClick={() => setIsOpen(!isOpen)} />
        </Header>
        <Navigation links={links} isOpen={isOpen} setIsOpen={setIsOpen} />
      </MenuWrapper>
    </Container>
  )
}

export default MobileMenu

const StyledLink = styled(Link)`
  .gatsby-image-wrapper {
    max-width: 162px;
    max-height: 49px;
  }
`

const Container = styled.div`
  display: none;
  cursor: pointer;
  @media (max-width: 750px) {
    display: flex;
    align-items: center;
    width: 180px;
    justify-content: space-between;
  }
`
const MenuWrapper = styled.div<{ open: boolean }>`
  display: ${({ open }) => (open ? "initial" : "none")};
  min-height: 100vh;
  width: 100vw;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: var(--primary-text);
  color: #fff;
  overflow-y: scroll;
`
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0 24px 0;
  width: 90%;
  margin: 0 auto;
`

const MenuIcon = styled(VscMenu)<{ home: number; changebg: number }>`
  height: 40px;
  width: 40px;
  color: ${({ home, changebg }) =>
    home && changebg ? "var(--primary-white)" : "var(--primary-text)"};
`
const CloseIcon = styled(VscClose)`
  height: 40px;
  width: 40px;
  color: var(--primary-white);
  cursor: pointer;
`

export const NavBookButton = styled.a<{
  home?: number
  changebg?: number
}>`
  text-decoration: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  //padding: 8px 16px;
  min-width: 120px;
  background: ${({ home, changebg }) =>
    home && changebg ? "var(--primary-white)" : "var(--primary-text)"};
  color: ${({ home, changebg }) =>
    home && changebg ? "var(--primary-text)" : "var(--primary-white)"};
  font-family: "Moderat Regular", sans-serif;
  font-size: 16px;
  text-transform: capitalize;
  max-width: 200px;
  height: 41px;
  cursor: pointer;
  transition: all 0.5s ease-in;
  &:hover {
    background: var(--primary-blue);
    color: var(--primary-white);
  }
`
